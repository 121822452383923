<template>
    <v-card flat tile>
        <v-card-text v-if="comments.length > 0">
            <comment v-for="comment in comments" :key="comment.id" :comment="comment" :knowledge="knowledge" />
        </v-card-text>
        <v-card-text>
            <add-comment :knowledge="knowledge" />
        </v-card-text>
    </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import comment from '@/components/knowledges/comment';
import addComment from '@/components/knowledges/addComment';
import OrderBy from 'lodash/orderBy';

export default {
    name: 'knowledge-comments',
    data: () => ({
        newComment: {
            description: '',
        },
        editComment: undefined,
    }),
    props: {
        knowledge: {},
    },
    components: {
        comment,
        addComment,
    },
    computed: {
        ...mapGetters(['profile']),
        comments() {
            return OrderBy(this.knowledge.comments, ['createdAt'], ['asc']);
        },
    },
    methods: {},
};
</script>

<style>
.comment-title .v-toolbar__content {
    padding: 4px 0px;
}
</style>
