<template>
    <v-card flat>
        <v-card-text>
            <markdown v-model="comment.description" :small="true" />

            <v-btn small color="success" outlined class="mt-4" @click="addComment">
                {{ $t('buttons.addComment') }}
            </v-btn>
        </v-card-text>
    </v-card>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import markdown from '@/components/shared/editor';

export default {
    name: 'knowledge-addComment',
    data: () => ({
        comment: {
            description: '',
        },
    }),
    props: {
        knowledge: {},
    },
    components: { markdown },
    computed: {},
    methods: {
        ...mapActions(['addCommentToKnowledge']),
        initComment() {
            this.comment = {
                description: '',
            };
        },
        async addComment() {
            await this.addCommentToKnowledge({
                id: this.knowledge.id,
                comment: this.comment,
            });
            this.initComment();

            this.$emit('change-attribute-value');
        },
    },
};
</script>
