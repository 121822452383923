<template>
    <v-card flat>
        <v-card-title class="subtitle-2">
            <v-toolbar dense flat class="comment-title" color="transparent">
                <label-user :value="comment.user" :avatar="true" :size="24" />
                <v-spacer />
                <v-btn
                    v-if="allowEditComment"
                    x-small
                    text
                    v-text="$t('buttons.edit')"
                    @click="modifyComment(comment)"
                ></v-btn>
            </v-toolbar>
        </v-card-title>
        <v-card-subtitle class="caption">
            <label-date :value="comment.createdAt" />
        </v-card-subtitle>
        <v-card-text v-if="editComment">
            <markdown v-model="editComment.description" v-on:save-content="saveComment" :small="true" />
            <v-toolbar dense flat>
                <v-spacer />
                <v-btn small color="red" text v-text="$t('buttons.cancel')" @click="initComment" />
                <v-btn small color="success" text v-text="$t('buttons.updateComment')" @click="saveComment" />
            </v-toolbar>
        </v-card-text>
        <v-card-text v-else>
            <labelMarkdown :md="comment.description" />
        </v-card-text>

        <v-toolbar dense flat color="transparent">
            <v-btn
                v-if="allowApproveComment"
                small
                @click="resolveComment()"
                :color="comment.resolve ? 'light-green darken-3' : 'blue-grey darken-2'"
            >
                <v-icon left>mdi-check-bold</v-icon>
                <template v-if="comment.resolve">{{ $t('knowledges.actions.comment_resolve') }}</template>
                <template v-else>{{ $t('knowledges.actions.comment_toresolve') }}</template>
            </v-btn>
            <v-chip v-if="!allowApproveComment && comment.resolve" color="light-green darken-3" label>
                <v-icon left>mdi-check-bold</v-icon>
                {{ $t('knowledges.actions.comment_resolve') }}
            </v-chip>
            <v-spacer />
            <v-btn text @click="likeComment()" :color="comment.likes.includes(profile.name) ? 'primary' : ''">
                <v-icon left>mdi-thumb-up</v-icon>
                {{ comment.likes.length }}
            </v-btn>
        </v-toolbar>
        <v-divider />
    </v-card>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import labelUser from '@/components/labels/user';
import labelDate from '@/components/labels/date';
import labelMarkdown from '@/components/labels/markdown';
import markdown from '@/components/shared/editor';
import { projectMemberMixin } from '@/mixins/member';
import Clone from 'lodash/cloneDeep';

export default {
    name: 'knowledge-comment',
    data: () => ({
        editComment: undefined,
    }),
    mixins: [projectMemberMixin],
    props: {
        knowledge: {},
        comment: {},
    },
    components: { labelUser, labelDate, labelMarkdown, markdown },
    computed: {
        ...mapGetters(['profile']),
        allowEditComment() {
            return this.comment.user === this.profile.name && this.editComment === undefined;
        },
        allowApproveComment() {
            return this.knowledge.createdBy === this.profile.name && this.editComment === undefined;
        },
    },
    methods: {
        ...mapActions(['updateCommentToKnowledge', 'likeCommentToKnowledge', 'dislikeCommentToKnowledge']),
        initComment() {
            this.editComment = undefined;
        },
        modifyComment() {
            this.editComment = Clone(this.comment);
        },
        async saveComment() {
            await this.updateCommentToKnowledge({
                id: this.knowledge.id,
                comment: this.editComment,
            });
            this.initComment();

            this.$emit('change-attribute-value');
        },
        likeComment() {
            if (this.comment.likes.includes(this.profile.name)) {
                this.dislikeCommentToKnowledge({
                    id: this.knowledge.id,
                    commentId: this.comment.id,
                });
            } else {
                this.likeCommentToKnowledge({
                    id: this.knowledge.id,
                    commentId: this.comment.id,
                });
            }
        },
        async resolveComment() {
            await this.updateCommentToKnowledge({
                id: this.knowledge.id,
                comment: {
                    id: this.comment.id,
                    resolve: !this.comment.resolve,
                },
            });
            this.initComment();

            this.$emit('change-attribute-value');
        },
    },
};
</script>
