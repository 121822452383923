<template>
    <div v-html="content.content" class="markdown-content"></div>
</template>
<script>
import { mapGetters } from 'vuex';
import { markdownIt } from '@/utils/markdown-render';
export default {
    name: 'markdown-label',
    data: () => ({}),
    props: {
        md: {},
    },
    computed: {
        content() {
            if (this.md) {
                return markdownIt(this.md);
            } else {
                return { content: '', toc: '' };
            }
        },
    },
    methods: {},
};
</script>

<style>
.emoji {
    width: 1rem;
}
</style>
