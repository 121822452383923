<template>
    <v-row v-if="knowledge">
        <v-col cols="10" offset="1">
            <v-card>
                <v-toolbar flat color="transparent">
                    <v-spacer></v-spacer>
                    <v-toolbar-title class="mx-2 title">{{ knowledge.name }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn text small @click="$router.go(-1)" class="mr-4">
                        {{ $t('buttons.back') }}
                    </v-btn>
                </v-toolbar>

                <v-card-text>
                    <description :knowledge="knowledge" :allow-editing="allowEditing" />
                </v-card-text>

                <v-divider />

                <v-card-text>
                    <knowledge-files ref="knowledgeFiles" :knowledge="knowledge" :allow-add="allowEditing" />
                </v-card-text>

                <v-card-text>
                    <knowledge-comments :knowledge="knowledge" />
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import description from '@/components/knowledges/description';
import knowledgeComments from './knowledge/comments.vue';
import knowledgeFiles from './knowledge/files.vue';

export default {
    name: 'knowledge-view',
    data: () => ({}),
    mixins: [],
    components: { description, knowledgeComments, knowledgeFiles },
    created() {
        this.findKnowledge(this.$route.params.knowledge_id);
    },
    watch: {},
    computed: {
        ...mapGetters(['profile', 'knowledge']),
        allowEditing() {
            return this.knowledge.createdBy === this.profile.name;
        },
    },
    methods: {
        ...mapActions(['findKnowledge']),
    },
};
</script>
<style scoped>
.v-divider {
    max-width: 80%;
    margin: 0 auto;
}
</style>
